import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import JsonViewer from 'vue-json-viewer'

Vue.use(VueRouter)
Vue.use(JsonViewer)

const routes = [
  {
    path: '*',
    name: '404',
    component: () => import('../views/404.vue'),
  },
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/questions',
    name: 'Questions',
    component: () => import('../views/Questions.vue'),
    beforeEnter: (to, from, next) => {
      let allow = process.env.NODE_ENV == 'development' ? true : false
      if(allow){
        next()
      }else{
        next({
          path: '/restricted'
        })
      }
    }
  },
  {
    path: '/tools/:survey',
    name: 'tools',
    component: () => import('../views/Survey.vue')
  },
  // {
  //   path: '/test/algorithm',
  //   name: 'Test',
  //   component: () => import('../views/TestAlgorithm.vue')
  // }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
