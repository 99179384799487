<template>
 <span v-html="output"/> 
</template>

<script>
export default {
    name: 'Str',
    props: {
        index: {
            type: String,
            required: true
        }
    },
    computed: {
        labels: function(){
            return this.$store.getters.labels
        },
        language: function(){
            return this.$store.getters.language
        },
        output: function(){
            let labels = this.labels
            let language = this.language
            let index = this.index

            if(labels===null){
                return "...loading..."
            }else if(labels[index] && labels[index][language]){
                return labels[index][language]
            }else if(labels[index] && labels[index].en){
                return labels[index].en
            }else{
                return "MISSING: "+index
            }
        }
    }
}
</script>

<style>

</style>